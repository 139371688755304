/*
   SPEED version 1.0
  
   Copyright ⓒ 2022 kt corp. All rights reserved.
   
   This is a proprietary software of kt corp, and you may not use this file except in 
   compliance with license agreement with kt corp. Any redistribution or use of this 
   software, with or without modification shall be strictly prohibited without prior written 
   approval of kt corp, and the copyright notice above does not evidence any actual or 
   intended publication of such software. 
*/
/* eslint-disable */

const buildType = "OP"; // OP, DEV

const state = {
  speedAgentDebug : false,
  speedAgentTrace : false,
  version : "3.0.5", //agent 버전
  windowsVersion : "3.0.5", //agent 버전
  macVersion : "3.0.5", //agent 버전
  // 품질측정 Client 관련 변수들
  client : {
    AGENT_VERSION        : "1.0.0.0", //품질,SLA 측정 클라이언트 버전
    AGENT_NAME_WIN       : "KTSpeedAgentSetup.exe", //품질,SLA 측정 클라이언트 윈도우 패키지명
    AGENT_NAME_WIN_NEWER : "KTSpeedClientSetup_new.exe", //품질,SLA 측정 클라이언트 윈도우 패키지명(신버전)
    AGENT_NAME_MAC       : "ollehspeed.pkg", //품질,SLA 측정 클라이언트 맥 패키지명
    AGENT_NAME_MAC_NEWER : "ktspeed.pkg", //품질,SLA 측정 클라이언트 맥 패키지명(신버전)
    AGENT_NAME_LNX       : "ollehspeed.tar.gz", //품질,SLA 측정 클라이언트 리눅스 패키지명
  },
  //시스템동작 옵션 변수들
  systemOption: {
    DefaultPageBlockSize : 10, //페이징 block size, 기본값, 페이지마다 변경가능
    DefaultPageRowSize   : 5,  //페이지 데이터 Row size, 기본값, 페이지마다 변경가능
    DefaultCryptKey      : "!toothbrush@", //결과데이터 암호화 모듈(encshineer ATL DLL) 키값
    DefaultDelimiterChar : "|", //결과데이터 parse 구분자
  },
  //측정동작 옵션 변수들 - 측정옵션을 DB로 부터 가져오지 못할경우 이값을 기본값으로 사용.
  measurementOption: {
    DefaultHopCount       : 30,  //'tracerout Max hop 기본값
    DefaultPingCount      : 200, //'udp ping 측정 횟수 기본값
    DefaultPingSize       : 64,  //'udp ping buffer size(byte) 기본값
    DefaultPingPort       : 8000, //udp ping 측정 port 기본값
    DefaultMPort          : 8002, //dn/up 측정 port 기본값
    DefaultGigaBuffSize   : 8192, //기가 측정 socket buffer size 기본값
    DefaultCommBuffSize   : 8192, //일반 측정 socket buffer size 기본값
    DefaultGigaTcpWinSize : 2097152, //기가 측정 tcp window size 기본값
    DefaultCommTcpWinSize : 524288, //일반 측정 tcp window size 기본값

    // 운용 측정서버
    Default10GigaMServer  : "121.156.34.195", //10기가 측정 L4 Ip address 기본값
    DefaultGigaMServer    : "121.156.34.195", //기가 측정 L4 Ip address 기본값
    //DefaultGigaMServer    : "10.214.116.111", //기가 측정 L4 Ip address 기본값
    DefaultCommMServer    : "121.156.34.195", //일반 측정 L4 ip address 기본값

    // 테스트 측정서버
    // Default10GigaMServer  : "10.217.182.218", // "121.156.34.195", //10기가 측정 L4 Ip address 기본값
    // DefaultGigaMServer    : "10.217.182.218", // "121.156.34.195", //기가 측정 L4 Ip address 기본값
    // DefaultCommMServer    : "10.217.182.218", // "121.156.34.195", //일반 측정 L4 ip address 기본값

    DefaultSLAInterval    : 5, //SLA 측정 간격(분) 기본값
    DefaultSLACount       : 5, //SLA 측정 횟수 기본값
    DefaultSLAThresHold   : 25, //SLA 임계치 기본값
    DefaultSLARate        : 60, //'SLA 만족여부(%) - 기본 미달횟수가 60% 이상이면 보상
    
    // dev : 60 prd : 300
    DefaultSLADelayTime   : 300 //SLA 측정 대기시간 (second) default 300초
    // DefaultSLADelayTime   : 60 //SLA 측정 대기시간 (second) default 300초
  },
};

const mutations = {};
const actions = {};
export default {
  buildType,
  state,
  mutations,
  actions
};