import { render, staticRenderFns } from "./SLAComplain.vue?vue&type=template&id=4efe3d5d&"
import script from "./SLAComplain.vue?vue&type=script&lang=js&"
export * from "./SLAComplain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports