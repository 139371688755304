<!-- 
   SPEED version 1.0
  
   Copyright ⓒ 2022 kt corp. All rights reserved.
   
   This is a proprietary software of kt corp, and you may not use this file except in 
   compliance with license agreement with kt corp. Any redistribution or use of this 
   software, with or without modification shall be strictly prohibited without prior written 
   approval of kt corp, and the copyright notice above does not evidence any actual or 
   intended publication of such software. 
 -->
<template>
  <div>
    <div class="csContsFea bg01_speed"> <!-- st.csContsFea bg01_speed -->
      <p>KT ID 로그인 후 속도측정이 가능하며 타사 인터넷 고객도 회원가입 후 이용 하실 수 있습니다.</p>
      <p><b>※ 최저보장속도(SLA) 기준 만족 여부는 '품질보증 테스트' 페이지에서 속도 측정 후 확인하실 수 있습니다.</b></p>
      <div class="btnbox">
        <a v-show="!showCompSpeedTestArea && strDSPList.length == 0"
          id="ifArea_btn" class="whtbtn btntolayer multiLineBtn" 
          href="#ifArea"
          @click="goTest($event, 0);">
          메가급 속도 테스트<br>
          <span class="smText">(100M이하)</span>
          <span class="tooltiptext toolreader">100M 이하<br>상품측정<span></span></span>
        </a>
        <a v-show="!showCompSpeedTestArea && strDSPList.length == 0"
          id="ifAreagiga_btn" class="whtbtn btntolayer multiLineBtn" 
          href="#ifArea"
          @click="goTest($event, 1);">
          기가급 속도 테스트<br>
          <span class="smText">(100M ~ 1G)</span>
          <span class="tooltiptext toolreader">100M ~ 1G<br>상품측정<span></span></span>
        </a>
        <a v-show="!showCompSpeedTestArea && strDSPList.length == 0"
          id="ifArea10giga_btn" class="whtbtn btntolayer multiLineBtn" 
          href="#ifArea"
          @click="goTest($event, 2);">
          10G 속도 테스트<br>
          <span class="smText">(1G ~ 10G)</span>
          <span class="tooltiptext toolreader">1G ~ 10G<br>상품측정<span></span></span>
        </a>
      </div>
      
      <div class="csContsArticles">
        <span>고객님이 현재 연결된 인터넷 회선에 대한 속도가 측정됩니다.</span>
      </div>
      <div class="btnbox" style="text-align : center; margin-top : 10px;" >
        <a href="https://shop.kt.com/wire/orderTelCounselForm.do?prodNo=WR00069223" class ="redbtn" 
        style="width : 500px; padding : 13px; border-radius : 35px; font-size : 21px; letter-spacing: 0.3px;">인터넷 요금제 업그레이드 신청</a>
      </div>
    
    </div> <!-- end.csContsFea bg01_speed -->

    <div id="speedtestguide" class="csContsDetail"> <!-- st.speedtestguide -->
    
      <!-- st.iframediv -->
      <div id="ifArea" class="iframediv layerfmbtn" :style="{ 'height' : showifAreaIframeProduct? '500px' : (showCompSpeedTestArea?  '900px': '')}">
        <div style="box-sizing:border-box; width:100%;">

          <!-- ifAreaIframeProduct --> <!-- 속도측정 상품 목록 -->
          <CompProductArea
            ref="CompProductArea"
            v-if="showifAreaIframeProduct"
            :parentPageName ="$options.name"
            :strDSPList="strDSPList"
          />
          
          <CompSpeedTestArea
            ref="CompSpeedTestArea"
            v-if="showCompSpeedTestArea"
            :selectedProductInfo ="selectedProductInfo"
            @onSpeedTestResult ="onSpeedTestResult"
          />

        </div>
      </div>
      <!-- end.iframediv -->
      
      <!-- 유의사항 안내 박스 수정 -->
      <div class="csContsArticles"> <!-- st.csContsArticles -->
        <div class="csbox">
          <h3>유의사항 안내</h3>
          <ul class="bulletlist">
            <li>속도 테스트 전에 프로그램 설치가 필요합니다.</li>
            <li>기가급 테스트는 측정 최적화를 위해 서버와 고객PC의 윈도우 사이즈를 변경하여 측정합니다.</li>
            <li>속도테스트는 KT인터넷 서비스를 이용하지 않는 고객께서도 KT ID 회원가입 후 이용 가능 합니다.</li>
            <li>이용자가 많은 시간대나 인터넷 이용 단말이 많을 경우, 측정 속도에 영향을 줄 수 있습니다.</li>
            <li>속도 측정 시 음성 안내 서비스가 제공되며, 고객 스피커 사양에 따라 지원이 어려울 수 있습니다.</li>
            <li>고객 정보보호를 위해 Safari 브라우저를 통한 서비스 이용이 제한되며, 다른 브라우저를 통해 이용이 가능합니다.</li>
            <li>해외 접속의 경우 서비스 이용이 제한됩니다.</li>
          </ul>
        </div>
      </div> <!-- end.csContsArticles -->
      <dl class="csContsDetailDlist"> <!-- st.csContsDetailDlist -->
        <dt>권장사양</dt><!-- 0803 클래스 here 삭제, 링크 태그 삭제 -->
        <dd id="csContsDetailDefinition01">
          <table class="rowtable" >
            <caption>속도테스트를 위한 시스템 권장, 최소 사양과 측정항목</caption>
            <colgroup>
              <col width="28%" />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">권장사양</th>
                <td>
                  <ul class="bulletlist">
                    <li>운영체제 : 윈도우즈 10 이상 / 맥OS 지원</li>
                    <li>브라우저 : Edge/크롬/whale/파이어폭스
                      <p>* 윈도우10 하위버전 및 인터넷익스플로러는 마이크로소프트사의 지원중단 정책에 따라 사용중 오류가 발생할 수 있습니다.</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row">측정항목</th>
                <td>
                  <ul class="bulletlist">
                    <li>다운로드 업로드 속도(최대값, 최소값, 평균, 편차)</li>
                    <li>UDP Ping 패킷 지연(최대값, 최소값, 평균, 편차, 손실 등)</li>
                    <li>경로 추적 테스트</li>
                    <li>OS 정보, CPU / RAM 정보</li>
                    <li>브라우저 종류 및 버전정보
                      <p>*익스플로러는 호환성 보기를 해제하셔야 정확한 버전을 알 수 있습니다.</p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
        <dt>측정방법</dt><!-- 0803 링크 태그 삭제 -->
        <dd id="csContsDetailDefinition02">
          <ul class="bulletlist">
            <li>측정구간은 고객 PC 에서 코넷(Kornet) 백본망에 위치한 측정서버까지 입니다. 다운로드/업로드 속도측정은 TCP 소켓 연결방식으로 측정을 진행하며 디스크 액세스를 최소화하는 방법으로 측정을 진행합니다. 또한 물리적인 링크 속도에 최대한 근접하는 업로드 속도 및 다운로드 속도를 측정하기 위하여 윈도우 사이즈 및 버퍼사이즈를 유동적으로 변경시키는 AMM(Adaptive Measurement Method) 방식을 사용합니다.</li>
            <li>타 ISP 의 초고속 인터넷 서비스를 이용하시는 분들은 IX (Internet Exchange) 구간을 거쳐서 속도 테스트를 수행하게 되므로 경유하는 홉수가 증가하게 됩니다.
              <div class="imgdiv"><img src="../../assets/images/img01_speedtestprocess.png" alt="고객 댁내 인입망 영역에서는 인터넷 서비스 가입자, ONT, G(E)-PON(OLT)를 거치고, KT 인터넷망에서 KORNET 백본를 거쳐 품질측정 서비스망 영역은 WEB, DB 영역을 거쳐 측정서버들이 있는 측정서버 Farm을 포함합니다."></div>
            </li>
          </ul>
        </dd>
      </dl> <!-- end.csContsDetailDlist -->
    </div> <!-- end.speedtestguide -->

    <PopupSpeedTestResultDetail ref="PopupSpeedTestResultDetail"></PopupSpeedTestResultDetail>
    <PopupCommonPage ref="PopupCommonPage"></PopupCommonPage>
  </div>
</template>

<script>
/* eslint-disable */
const requestIp = require('request-ip');
export default {
  name: 'SpeedTestIntroduce',
  components: {
    CompProductArea            :()=>import('@/components/speed/CompProductArea'),
    CompSpeedTestArea          :()=>import('@/components/speed/CompSpeedTestArea'),
    PopupSpeedTestResultDetail :()=>import('@/popup/speed/PopupSpeedTestResultDetail'),
    PopupCommonPage            :()=>import('@/popup/common/PopupCommonPage')

  },
  data() {
    return {
      kt_token                : null,
      strDSPList              : [],
      showifAreaIframeProduct : false,
      showCompSpeedTestArea   : false,
      selectedProductInfo     : null,
      searchProductInfo       : null
    };
  },
  beforeRouteUpdate() {},
  beforeCreate() {},
  created() {
    this.setSpeedTestEventBus("$on");
  },
  mounted() {},
  beforeUpdate(){},
  updated() {},
  beforeDestroy(){},
  destroyed(){
    this.setSpeedTestEventBus("$off");
  },
  methods: {
    /** --------------------------------------------------------
     * 속도테스트 set eventBus.
     */
    async setSpeedTestEventBus( type ) {
      switch( type ) {
        case "$on" :
          this.$eventBus.$on("SpeedTestIntroduce/setProductList", this.setProductList);
          this.$eventBus.$on("SpeedTestIntroduce/goProductTest", this.goProductTest);
          this.$eventBus.$on("SpeedTestIntroduce/setSearchProductInfo", this.setSearchProductInfo);
          this.$eventBus.$emit("setCurrentPageName", { currentPageName : this.$options.name });
          this.$nextTick(() => {
            this.pageInitDefault();
          });
          break;
        case "$off":
          this.$eventBus.$off("SpeedTestIntroduce/setProductList", this.setProductList);
          this.$eventBus.$off("SpeedTestIntroduce/goProductTest", this.goProductTest);
          this.$eventBus.$off("SpeedTestIntroduce/setSearchProductInfo", this.setSearchProductInfo);
          break;
        default : break;
      }
    },
    /** --------------------------------------------------------
     * 상품 목록 set.
     * @param {*} params 
     */
    setSearchProductInfo(params) {
      this.searchProductInfo = params;
    },
    setProductList(list) {
      this.showifAreaIframeProduct = (list.length > 0)? true : false;
      this.strDSPList = list;
      this.selectedProductInfo = null;
    },
    /** --------------------------------------------------------
     * 속도테스트 버튼.
     * -메가, 기가, 10G
     */
     goTest(e, mtype){
      e.preventDefault();
      if ( window.kt.isLogin()=="N" ) {
        window.kt.popupLogin();
      } else {
        switch(this.currentPageName) {
          case "SpeedTestIntroduce" :
            this.goProductTest({ mtype : mtype});
            break;
          case "SLATestIntroduce" :
            if (mtype==0) {
              this.pageEventEmit("goTest");
            }
            break;
          default : break;
        }
      }
    },
    /** --------------------------------------------------------
     * 속도 테스트 버튼.
     */
    goProductTest(selectedProductInfo) {
      this.setProductList([]);
      this.selectedProductInfo = selectedProductInfo;
      this.showCompSpeedTestArea = true;
    },
    /**
     * 측정결과 수신
     * 속도테스트 결과 수신.
     */
    async onSpeedTestResult(testResult){
      var result = !!testResult? testResult : null;
      //상품 없는 사용자는 mSAID 없이 insert.
      var mSAID = await this.getOTMOwnerSubscpnInfoByUserName(this.selectedProductInfo);
      var params = {
          // kt_token_id            : this.cookiesInfo.kt_token_id
        // , v_user_id              : this.decryptedInfo.userid
          v_sidoindex            : null
        , v_gugunindex           : null
        , v_measure_type         : this.searchProductInfo.mtype
        , v_processorname        : this.$ktSpeedPlugin.notNullValueReturn("v_processorname", result.CPU_TYPE)
        , v_os                   : this.$ktSpeedPlugin.notNullValueReturn("v_os", result.OS)
        , v_nic                  : this.$ktSpeedPlugin.notNullValueReturn("v_nic", result.NIC)
        , v_mac                  : this.$ktSpeedPlugin.notNullValueReturn("v_mac", result.MAC)
        , v_internal_address     : this.$ktSpeedPlugin.notNullValueReturn("v_internal_address", result.IP)
        , v_external_address     : null //was에서 input.
        , v_down_tcpwindowsize     : result.TCP_WINDOW_DOWN
        , v_up_tcpwindowsize       : result.TCP_WINDOW_UP
        , v_test_time              : result.TEST_TIME
        , v_down_exception_traffic : 0
        , v_down_speed_avg_byte    : result.DOWN_AVG
        , v_down_speed_sdv_byte    : result.DOWN_STD // arrSpeedParamList(11)
        , v_down_speed_max_byte    : result.DOWN_MAX // arrSpeedParamList(12)
        , v_down_speed_min_byte    : result.DOWN_MIN // arrSpeedParamList(13)
        , v_up_speed_avg_byte      : result.UP_AVG // arrSpeedParamList(14)
        , v_up_speed_sdv_byte      : result.UP_STD // arrSpeedParamList(15)
        , v_up_speed_max_byte      : result.UP_MAX // arrSpeedParamList(16)
        , v_up_speed_min_byte      : result.UP_MIN // arrSpeedParamList(17)
        , v_ping_avg_rtt           : result.UDP_AVG // arrSpeedParamList(18)
        , v_ping_sdv_rtt           : result.UDP_STD // arrSpeedParamList(19) 
        , v_ping_max_rtt           : result.UDP_MAX // arrSpeedParamList(20) 
        , v_ping_min_rtt           : result.UDP_MIN // arrSpeedParamList(21)
        , v_ping_loss_count        : result.UDP_LOSS_COUNT // arrSpeedParamList(22)
        , v_ping_count             : result.UDP_COUNT // arrSpeedParamList(23)
        , v_ping_size              : result.UDP_DATA_SIZE // arrSpeedParamList(24)
        , v_target_server_ip       : result.SERVER_REAL_IP
        , v_cpu_usage              : result.CPU_RATE // arrSpeedParamList(25)
        , v_browser                : this.$ktSpeedPlugin.notNullValueReturn("v_browser", result.BROWSER_CLIENT)
        , v_mem_usage              : result.MEMORY_RATE // arrSpeedParamList(26) 
        , v_memory                 : result.MEMORY_SIZE // arrSpeedParamList(27) 
        //, target_server_ip       : result.SERVER_IP_SPEED // NulltoNAChar(arrSpeedParamList(28))
        , v_branch_name            : this.selectedProductInfo.branchname // arrSpeedParamList(29)
        , v_agent_name             : this.selectedProductInfo.agentname // arrSpeedParamList(30)
        , v_office_name            : this.selectedProductInfo.officename // arrSpeedParamList(31)
        , v_sa_id                  : mSAID // arrSpeedParamList(32)
        , v_product_id             : this.selectedProductInfo.product_id // arrSpeedParamList(33)
        , v_product_name           : this.selectedProductInfo.product_name // arrSpeedParamList(34)
        , v_last_sa_id             : this.selectedProductInfo.said // arrSpeedParamList(35)
        , v_tracertresult          : result.TRACEROUTE
        , v_qindex                 : 0 
        , v_wifi                   : result.WIFI === undefined ? 'UNKNOWN' : result.WIFI
        , v_ssid                   : result.SSID === undefined ? 'UNKNOWN' : result.SSID
        , v_vaccine                : result.VACCINE === undefined ? 'UNKNOWN' : result.VACCINE
        , v_link_speed             : result.LINK_SPEED === undefined ? 'UNKNOWN' : result.LINK_SPEED
        , v_order_flag             : this.selectedProductInfo.pp_oder_flag === undefined ? '' : this.selectedProductInfo.pp_oder_flag
      };
      //Object.assign(params, result, this.selectedProductInfo, this.decryptedInfo, this.searchProductInfo)
      params.v_branch_name = (this.selectedProductInfo.branchname=="-")? "" : this.selectedProductInfo.branchname;
      params.v_agent_name  = (this.selectedProductInfo.agentname=="-")? "" : this.selectedProductInfo.agentname;
      params.v_office_name = (this.selectedProductInfo.officename=="-")? "" : this.selectedProductInfo.officename;
      
      
      var insertSpeedTestResult = await this.$http.post(this.returnUrl("/speedtest/insertSpeedTestResult"), params).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch((error)=> {
          return null;
      });

      if ( !!insertSpeedTestResult && !!this.$refs.PopupSpeedTestResultDetail ) {
        var params = {
          qindex : insertSpeedTestResult.qindex
        };
        this.$refs.PopupSpeedTestResultDetail.popupVisible(params);

        var uParams = {
          qindex                      : insertSpeedTestResult.qindex
          , v_mac                     : this.$ktSpeedPlugin.notNullValueReturn("v_mac", result.MAC)
          , v_ip                      : this.$ktSpeedPlugin.notNullValueReturn("v_internal_address", result.IP)
          , v_last_sa_id              : this.selectedProductInfo.said === undefined ? '' : this.selectedProductInfo.said
          , v_scn                     : this.selectedProductInfo.subscpn_id === undefined ? '' : this.selectedProductInfo.subscpn_id
          // , v_user_id                 : this.decryptedInfo.userid
          // , kt_token_id            : this.cookiesInfo.kt_token_id
        }
        this.$http.post(this.returnUrl("/speedtest/updateSpeedTest"), uParams)
      }
    }
  }

}
</script>

<style scoped>
.toolreader {visibility: visible!important; opacity: 0; }
.csContsFea .btnbox .whtbtn:focus-visible .tooltiptext {opacity: 1;}

</style>
