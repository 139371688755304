<template>
  <div id="cfmOllehPopWrap" class="pop_dissent">
    <!-- custom-class="medium_dialog" -->
    <el-dialog
      title="속도측정 상세이력"
      custom-class="slaTestResultDetailPopup"
      center
      :visible.sync="dialogVisible"
      :before-close="cancel"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :append-to-body="true"
      :modal="true"
      >
      <div>
        <a href="#" class="btn_close" @click="cancelBtn">
          <img src="../../assets/images/btn_close.jpg" alt="창 닫기" />
        </a>
        
        <h2><img src="../../assets/images/sla_h2_1.png" alt="SLA 테스트 이력 상세보기" /></h2>
        
        <div class="test_table type1" style="margin-bottom:20px;">
          <table>
            <caption>SLA 테스트 이력 상세보기</caption>
            <colgroup>
              <col width="160px" />
              <col width="*" />
            </colgroup>
            <tbody>
              <tr>
                <th class="bg_typ_top bg_fa">측정일자</th>
                <td class="bg_typ_top">
                  {{detailInfo.measured_date}}
                </td>
              </tr>
              <tr>
                <th class="bg_fa">상품명</th>
                <td>
                  {{detailInfo.product_name}}
                </td>
              </tr>
              <tr>
                <th class="bg_fa">SLA기준속도</th>
                <td>
                  {{detailInfo.download_sla}} Mbps
                </td>
              </tr>
              <tr>
                <th class="bg_fa">측정횟수</th>
                <td>
                  {{detailInfo.tcnt}} 회
                </td>
              </tr>
              <tr>
                <th class="bg_fa">미달횟수</th>
                <td>
                  {{detailInfo.slacnt}} 회
                </td>
              </tr>
              
              <tr v-if="mtype==0">
                <th class="bg_fa">결 과</th>
                <td>
                  {{strDSPResult}}
                </td>
              </tr>
              
            </tbody>
          </table>
        </div>
        
        <div class="test_table type3" style="margin-bottom:15px;">
          <table>
            <caption>SLA 테스트 이력 상세보기</caption>
            <colgroup>
              <col style="width:10%;"/>
              <col style="width:25%;"/>			
            </colgroup>
            <thead>
              <tr>
                <th class="bg_typ_top">측정차수</th>
                <th class="bg_typ_top">다운로드(Mbps)</th>
              </tr>
            </thead>
            <tbody>
              <!-- strDSPList -->
              <tr v-for="(item, index) in measuredList" :key="'measuredList_'+index+'_'+item.seq">
                <td>{{index+1}}</td>
                <td>{{item.dwavgText}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        
        <!-- <div style="margin : 0 20px 20px;">
          <span style="font-size:13px; font-family:'돋움'">
            <b>* SLA기준값은 서비스 종류에 따라 다르게 적용되며, 서비스별 SLA 기준값은 별도로 문의하여 주시기 바랍니다.</b>
          </span>
          <br>
          <br>
          <span style="font-size:13px; font-family:'돋움'">
            <b>* 속도측정 결과 품질보증(SLA) 기준에 만족함에도 A/S점검을 원하시는 경우,'<a href="#" style="text-decoration: none !important; color:blue;" onclick="homeAsReqStatus()">홈상품 A/S신청 및 변경</a>'페이지에서 신청 가능합니다. 고객 사유에 의한 기사 방문시 출동비(부가세 포함 15,000원)가 청구될 수 있습니다.</b>
          </span>
        </div> -->
        
        <div style="margin : 0 20px 20px;">
          <span style="font-size:13px; font-family:'돋움'">
            <b>* 표기된 이용중인 상품의 SLA(품질보증)기준속도로 측정결과 미달 여부를 판단합니다.</b>
          </span>
          <br>
          <br>
          <span v-if="(!IsOK && mtype=='0') && (objectionResultWifiFlag || objectionResultAntmFlag || objectionResultSpeedFlag || objectionResultCemsFlag)" style="color:red; font-size:15px;">
            품질 측정 결과는 댁내 인터넷 환경에 따라 낮게 측정 될 수 있습니다. <br>
            아래의 점검결과를 확인하신 후 재측정 하거나 방문점검 신청해 주시기 바랍니다.<br>
            * 유의사항 : 방문점검 시 회선에 이상이 없는 경우 출동비가 부과될 수 있습니다.
          </span>
          <span v-if="IsOK && mtype=='0'" style="font-size:13px; font-family:'돋움'"> 
            <br>
            <b>* 속도측정 결과 SLA(품질보증) 기준에 만족함에도 A/S점검을 원하시는 경우, KT.com 홈페이지'고객지원 - 홈상품 A/S신청 및 변경'페이지에서 신청 가능합니다. 고객 사유에 의한 기사 방문시 출동비(부가세 포함 15,000원)가 청구될 수 있습니다.</b>
          </span>
          <!-- <span v-if="!IsOK && mtype=='0'" style="text-decoration: none !important; color:red;">
            <b style="font-size:13px; font-family:'돋움'">* 품질점검을 원하시는 경우 방문점검이 가능하며, 점검결과 이상이 없는 경우 출동비가 부과될 수 있으므로 아래의 품질측정 환경 등 상세 내용을 먼저 확인해 주시기 바랍니다.</b>
          </span> -->
        </div>
        
         <div class="test_table_guide03" v-if="(!IsOK && mtype=='0') && (objectionResultWifiFlag || objectionResultAntmFlag || objectionResultSpeedFlag || objectionResultCemsFlag)" style="margin-top: 0px;">
          <!-- <strong>* 품질점검을 원하시는 경우 방문점검이 가능하며, 점검결과 이상이 없는 경우 출동비가 부과될 수 있으므로 아래의 품질측정 환경 등 상세 내용을 먼저 확인해 주시기 바랍니다.</strong> -->
          <div  class="test_table_guide_list">

            <div  v-if="objectionResultCemsFlag">
              <p>[KT인터넷 제공속도 자동점검결과]</p>
              <div class="">
                <p>현재 이용중이신 KT인터넷의 자동점검 속도는 <strong>가입상품 보증 기준을 충족하고 있습니다.</strong><br><strong>KT 단말까지의 다운로드속도 측정결과</strong> : {{ objectionResult.cems_speed }} Mbps </p>   
              </div>
            </div>
            <div v-if="objectionResultWifiFlag || objectionResultAntmFlag || objectionResultSpeedFlag" >
              <p>[측정환경 자동점검 결과]</p>
              <div>
                <p v-if="objectionResultWifiFlag"><strong>무선인터넷(WiFi)을 통해 속도측정을 하셨습니다.</strong><br> 무선환경 측정결과는 상황에 따라 측정속도가 정확하지 않아 <strong>보증 대상이 아니니 유선인터넷으로 접속 하신 후</strong> 다시 측정해 주세요.</p>
                <p v-if="objectionResultAntmFlag"><strong>가입하신 회선이 아닌 곳에서 측정한 경우는 품질보증 대상이 아닙니다.</strong><br> 가입하신 회선에 접속하여 측정한 것이 맞는지 확인하시고 그렇지 않은 경우는 해당 회선에 접속 후 다시 측정해 주세요.</p>
                <p v-if="objectionResultSpeedFlag">PC와 모뎀 사이에 속도가 낮게 설정되어 있습니다.<br> <strong>PC와 모뎀의 설정상태나 사양의 문제가 없는지 확인 하시고</strong> 문제가 있을 경우 정상조치 후 다시 측정해 주세요.</p>
              </div>
           </div>
            

          </div>
        </div>

        <!-- v-if="!IsOK && mtype=='0'" -->
        <!-- <div v-if="!IsOK && mtype=='0'" style="margin : 0 20px 20px;">
          b style="font-size:13px; color : #d71826; font-family:'돋움'">* 품질점검을 위한 엔지니어 방문을 위해 연락가능한 전화번호를 입력 후 저장하시면 자동으로 이의신청 접수됩니다.</b
          <b style="font-size:13px; font-family:'돋움'">* 품질점검을 위한 엔지니어 방문을 위해 연락가능한 전화번호를 입력 후 저장하시면 방문점검 접수됩니다.</b>
        </div> -->
        <div v-if="!IsOK && mtype=='0'"
          style="width:100%; text-align:center; font-size:13px; font-family:'돋움'">
            <el-radio-group v-model="inputData.checkedType" @change="changeRadio">
              <el-radio label="hp"> 휴대폰 </el-radio>
              <el-radio label="ti"> 집전화/인터넷전화 </el-radio>
            </el-radio-group>
          <br>
          <div style="margin-top:10px">
            <span style="margin-right:10px;">연락처</span>
            <el-select v-show="inputData.checkedType=='hp'"
              v-model="inputData.selectHp" size="mini" placeholder="" style="width:70px;" @change="changeSelect">
              <el-option v-for="(item, index) in selectHpList" :key="'selectHpList_'+index"
                :value="item.value"
                :label="item.valueName">
              </el-option>
            </el-select>
            
            <el-select v-show="inputData.checkedType=='ti'"
              v-model="inputData.selectTi" size="mini" placeholder="" style="width:70px;" @change="changeSelect" >
              <el-option v-for="(item, index) in selectTiList" :key="'selectTiList_'+index"
                :value="item.value"
                :label="item.valueName">
              </el-option>
            </el-select>
            -
            <el-input size="mini" type="text" name="telnum2" v-model="inputData.telnum2" maxLength="4" style="width:70px;"/>
            <!-- <input id="telnum2" type="text" style="width : 50px; height:29px; border:1px solid #333333; padding:3px 5px; text-align:center;" maxlength="4" onkeypress="return checkNumber(event)" /> -->
            -
            <el-input size="mini" type="text" name="telnum3" v-model="inputData.telnum3" maxLength="4" style="width:70px;"/>
            <!-- <input id="telnum3" type="text" style="width : 50px; height:29px; border:1px solid #333333; padding:3px 5px; text-align:center;" maxlength="4" onkeypress="return checkNumber(event)" /> -->
          </div>
        </div>

        <div style="margin-top:15px;" class="common_red_btn">
          <a v-if="!IsOK && mtype=='0'" href="#" class="sla_popup_detail_confirmCompAction_btn" @click="confirmCompAction">확인</a>
          <a v-else href="#" class="sla_popup_detail_showModalPop_btn" @click="showModalPop">확인</a>
        </div>
        
        <!-- <div class="common_red_btn">
          <a href="#" class="sla_popup_detail_showModalPop_btn" @click="showModalPop();">확인</a>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'PopupSLATestResultDetail',
  props: {},
  components: {},
  data () {
    return {
      dialogVisible: false,
      searchParams: {},
      detailInfo: {},
      strDSPTracert : [],
      IsOK : true,
      mtype : 0,
      strDSPResult : null,
      measuredList : [],
      selectHpList : [
        { value : "010" , valueName : "010" },
        { value : "011" , valueName : "011" },
        { value : "016" , valueName : "016" },
        { value : "017" , valueName : "017" },
        { value : "018" , valueName : "018" },
        { value : "019" , valueName : "019" },
        { value : "0130", valueName : "0130" }
      ],
      selectTiList : [
        { value : "02" , valueName : "02" },
        { value : "031", valueName : "031" },
        { value : "032", valueName : "032" },
        { value : "033", valueName : "033" },
        { value : "041", valueName : "041" },
        { value : "042", valueName : "042" },
        { value : "043", valueName : "043" },
        { value : "044", valueName : "044" },
        { value : "051", valueName : "051" },
        { value : "052", valueName : "052" },
        { value : "053", valueName : "053" },
        { value : "054", valueName : "054" },
        { value : "055", valueName : "055" },
        { value : "061", valueName : "061" },
        { value : "062", valueName : "062" },
        { value : "063", valueName : "063" },
        { value : "064", valueName : "064" },
        { value : "070", valueName : "070" },
      ],
      inputData : {
        checkedType : "hp",
        selectHp : "010",
        selectTi : ""
      },
      objectionResult: {},
      objectionResultAntmFlag: false,
      objectionResultSpeedFlag: false,
      objectionResultWifiFlag: false,
      objectionResultCemsFlag: false,
      objectionCompResult : false
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    cancel() {
      this.detailInfo = {};
      this.dialogVisible = false;
    },
    cancelBtn(e) {
      e.preventDefault();
      this.cancel();
    },
    async popupVisible(params) {
      this.inputData = {
        checkedType : "hp",
        selectHp : "010",
        selectTi : "",
        telnum2 : "",
        telnum3 : "",
      };

      this.dialogVisible = true;
      this.searchParams = !!params && params.searchParams? params.searchParams : null;
      
      // if ( !this.searchParams && !!params.qindex ) {
      //   this.searchParams = {
      //     qindex : params.qindex
      //   };
      // }
      
      await this.getSlaTestResultDetailInfo();
    },
    /**
     * SLA 테스트 결과 조회.
     */
    async getSlaTestResultDetailInfo() {
      var searchParams = !!this.searchParams ? this.searchParams : null;
      var data = null;
      
      if (!!this.searchParams) {
        var params = {
          sla_index : this.searchParams.sla_index
        };
        data = await this.$http.get(this.returnUrl("/speedtest/getSLATestResultInfo"), {params : params}).then(
          async (response)=>{
            var status = !!response.status? response.status : null;
            var data = !!response.data ? response.data : null;
            return data;
        }).catch(async (error) => { 
          return null;
        });

        var SLACount  = this.$store.getters.measurementOption.DefaultSLACount;
        var SLARate   = this.$store.getters.measurementOption.DefaultSLARate;
        
        if (!!data) {
          this.detailInfo = data.result;
          var mtotalcnt = this.detailInfo.tcnt;
          var mslacnt   = !!this.detailInfo.slacnt? this.detailInfo.slacnt : 0;
          var mrate     = !!mtotalcnt? Number((mslacnt/mtotalcnt)*100).toFixed(2) : "0.00";
          
          if ( SLACount > mtotalcnt ) {
            this.strDSPResult = "SLA 측정횟수가 "+ mtotalcnt + "회 로서 SLA기준횟수("+ SLACount + "회)에 부족합니다.";
            this.IsOK = true;
          } else if ( mrate >= SLARate ) {
            // this.strDSPResult = "SLA 미달횟수가 "+ mrate + "%로서 기준(60%) 이상입니다. 이의신청이 가능합니다.";
            this.strDSPResult = "SLA 미달횟수가 "+ mrate + "%로서 기준(60%) 이상입니다. 품질점검 신청이 가능합니다.";
            this.IsOK = false;
          } else {
            this.strDSPResult =  "SLA 미달횟수가 " + mrate + "%로서 기준(60%)미만입니다.";
            this.IsOK = true;
          }

          var tempList = [];
          for(var item of data.measuredList) {
            item.down_speed_avg_byte = Number(item.down_speed_avg_byte * 8 / 1000000).toFixed(1);
            item.dwavgText = !!(item.down_speed_avg_byte==0)? "측정실패" : item.down_speed_avg_byte;
            tempList.push(item);
          }
          this.measuredList = tempList;
          // console.log('DATA : ', data)

          this.objectionResult = data.objectionResult;
          this.objectionResultAntmFlag = this.objectionResult.objectionResultAntmFlag
          this.objectionResultSpeedFlag = this.objectionResult.objectionResultSpeedFlag
          this.objectionResultWifiFlag = this.objectionResult.objectionResultWifiFlag
          this.objectionResultCemsFlag = this.objectionResult.objectionResultCemsFlag

          this.objectionCompResult = (this.objectionResultAntmFlag || this.objectionResultSpeedFlag || this.objectionResultWifiFlag ||  this.objectionResultCemsFlag);
          
        }
      }
    },
    async confirmCompAction(e) {
      //alert("연락처를 입력해주세요.");
      e.preventDefault();
      if ( !this.inputData.telnum2 || !this.inputData.telnum3 ) {
        //alert("연락처를 입력해주세요.");
        //this.$alert("연락처를 입력해주세요.", {type: 'error'});
        this.$message('연락처를 입력해주세요.');
        return false;
      }
      
      var numberReg = /^([0-9]){4}$/;
      var numberReg2 = /^([0-9]){3,4}$/;
      var telnum2Check = null;
      var telnum3Check = numberReg.test(this.inputData.telnum3);
      if ( this.inputData.checkedType == "hp" ) {
        telnum2Check = numberReg.test(this.inputData.telnum2);
      } else {
        // 일반 전화이면 가운데 자리수는 3~4자리 체크.
        telnum2Check = numberReg2.test(this.inputData.telnum2);
      }
      
      if ( !telnum2Check || !telnum3Check ) {
        //alert("연락처 형식에 맞지 않습니다.");
        this.$message('연락처 형식에 맞지 않습니다.');
        return false;
      }

      var telnumtype = this.inputData.checkedType == "hp"? "H" : "T";
      var telnum1 = this.inputData.checkedType == "hp"? this.inputData.selectHp : this.inputData.selectTi;
      var telnum2 = this.inputData.telnum2;
      var telnum3 = this.inputData.telnum3;

      var telnum = telnum1 +"-" + telnum2+ "-" + telnum3;
      var msg = this.setModalMsg();
      
      // '<%=m_totUseqntEvAplyThrs %>' == "Y" ? "데이터 일일사용총량 초과로 확인됩니다. 하루 지나서 다시 측정해 보시길 권해드립니다. 그래도 이의신청하시겠습니까?" : "이의신청 하시겠습니까?";
     // var msg = "이의신청 하시겠습니까?";
     
      
      if ( this.searchParams.totuseqntevaplythrs == "Y" ) {
        msg = "데이터 일일사용총량 초과로 확인됩니다. 하루 지나서 다시 측정해 보시길 권해드립니다. 그래도 이의신청하시겠습니까?";
      }

      var sla_index = this.searchParams.sla_index;
      
      var complainParam = {
        sla_index : sla_index,
        telnum    : telnum,
        telnumtype: telnumtype
      }


     if(this.objectionCompResult){
        this.$confirm( msg, { confirmButtonText: '확인', cancelButtonText: '취소', type: 'warning' ,dangerouslyUseHTMLString: true }).then(
        async (confirm) => {
          this.comfirmComp(complainParam);
        
      }).catch(async (c)=>{
        this.cancel();
      });     
     }else{
        this.comfirmComp(complainParam);
     }
    },
    /**
     * "이미 품질보증(SLA) 이의신청이 접수 되었습니다."
     */
    satisfaction() {
      //"이미 품질보증(SLA) 이의신청이 접수 되었습니다."
      // 서비스를 이용해 주셔서 감사합니다. 인터넷 속도측정 과정에 대한 고객 경험 만족도 조사를 진행하고 있습니다. 참여 부탁 드립니다.
      this.$confirm( "품질보증(SLA) 이의신청이 접수 되었습니다. SLA처리 기준에 따라 진행되며 별도로 연락을 드립니다.<br><br>아울러 인터넷 속도측정 과정에서 고객 경험에 대한 만족도 조사를 진행하고 있습니다. 많은 참여 부탁드립니다. "
        , { confirmButtonText: '만족도 조사 참여', cancelButtonText: '종료하기', type: 'warning', dangerouslyUseHTMLString: true }).then(
        async (confirm) => {
          var m_ci = {
              custLdinCh : "WS"
            , custIdfyTypeCd : "5"
            , custIdfyTypeVal : this.detailInfo.user_id
            , custScrnIndcNm : ""
          };

          var m_sr = {
              rfrn1 : this.detailInfo.sa_id
            , rfrn2 : ""
            , rfrn3 : ""
            , rfrn4 : ""
            , rfrn5 : ""
          };

          var params = {
            m_ci : JSON.stringify(m_ci),
            m_sr : JSON.stringify(m_sr)
          };

          // 만족도 조사 window 팝업 open관련 parameter 암호화.
          var result = await this.$http.get(this.returnUrl("/speedtest/getEnccidata"), {params : params}).then(
            async (response)=>{
              return !!response.data? response.data : null;
          }).catch((error)=> {
              return null;
          });
          var base   = this.$store.getters.scriptUrl.CSATURL+"/web/widget/sdeg.html?";
          var kValue = 'k='  + this.$store.getters.scriptUrl.CXDUSLAKEY;
          var wValue = '&w=' + "INET_SPEED_SLA_SDEG";
          var cValue = "&c=" + result.enccidata;
          var aValue = "&a=" + result.encsrdata;
          window.open(base + kValue + wValue + cValue + aValue, 'vivaldiSdegPop','width=460, height=750, toolbar=no, location=no, status=no, menubar=no');

          this.cancel();
        }).catch(async (c)=>{
          this.cancel();
        });
    },
    changeRadio(type) {
      if ( this.inputData.checkedType=="hp" ) {
        this.inputData.selectHp = "010"
        this.inputData.selectTi = "";
      } else {
        this.inputData.selectHp = ""
        this.inputData.selectTi = "02";
      }
      
      this.inputData.telnum2 = "";
      this.inputData.telnum3 = "";
    },
    changeSelect(type) {
    },
    showModalPop(e) {
      e.preventDefault();
      this.cancel();
    },
    setModalMsg(){
      var result = ""
 
      var msg1  = "KT에서 현재 제공해드리는 인터넷 속도는 자동점검결과 가입 상품의 보증기준을 만족하고 있습니다.<br>";
      var msg2  = "방문점검 시 회선에 이상이 없는 경우(PC, 공유기등의 댁내 환경 문제 시) 출동비가 부과될 수 있습니다. 방문점검을 신청 하시겠습니까?";
     
      var objectionResultStatus = (this.objectionResultWifiFlag || this.objectionResultAntmFlag || this.objectionResultSpeedFlag)? true : false;



      if(this.objectionResultCemsFlag){
           result += msg1;
           result += msg2;
      }else{
        if(objectionResultStatus){
           result = msg2;
        }else{

        }
      }
      return result;
    },
    async comfirmComp(complainParam){
      var complainCheckParam = {
            sla_index : complainParam.sla_index
          };
          // SLA 측정 결과 상세 팝업에서 이의신청 전에 이의신청 건수 조회.
      var complainCountResult = await this.$http.get(this.returnUrl("/speedtest/getSLAComplainHistoryIndexCount"), { params : complainCheckParam }).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch((error)=> {
          return null;
      });

      if ( !!complainCountResult && complainCountResult.resultTotalCount == 0 ) {
        var complainInsertParams = {
          sla_index     : complainParam.sla_index,
          telnum        : complainParam.telnum,
          telnumtype    : complainParam.telnumtype
        };
        var complainCountResult = await this.$http.post(this.returnUrl("/speedtest/insertSLAComplain"), complainInsertParams).then(
        async (response)=>{
            var resultData = !!response.data? response.data : null;
            return resultData;
        }).catch((error)=> {
            return null;
        });
        // 이의신청.
        if ( !!complainCountResult && complainCountResult.insertResult == "success") {
          this.satisfaction();
        } else {
          return false;
        }
      } else {
        this.$alert('이미 품질보증(SLA) 이의신청이 접수 되었습니다.', '', {
          confirmButtonText: '확인',
          callback: action => {
            this.cancel();
          }
        });
      }
    }
  }
  
}
</script>
<style>
/* kt base.css에서 padding: 0px로 만들어서 버튼이 줄어드는 현상이 있어서 추가.*/
button, button:focus, button:active {
  padding : 9px 15px !important;
}
</style>

<style scoped>
/* .el-input::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
} */
/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
} */
/* .el-button>span{
  display:inline-block;
  font-size:13px !important;
  transform: rotate(-0.03deg);
  letter-spacing: -0.5px;
}
.el-button--mini{
  padding:6px 10px !important;
  padding-bottom:8px !important;
} */

/* .el-dialog,
.el-dialog--center {
  border-radius: 10px;
  filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.5));
}

.el-dialog--center .el-dialog__body {
  text-align: left;
} */
/* .el-message-box__btns .el-button {
  border:1px solid red !important;
} */

/* 20240502 speed안내 팝업 가시성 개선 추가 */
.test_table_guide03 {font-size: 15px; line-height: 1.45; margin : 0 20px 20px;}
.test_table_guide03 .test_table_guide_list {padding-bottom: 8px;}
.test_table_guide03 .test_table_guide_list div > div > p {background: #fff ; color: #606266;}
.test_table_guide03 .test_table_guide_list div  div > p {padding-left: 9px; margin-bottom: 5px;}
.test_table_guide03 .test_table_guide_list > div> p {color: #606266; font-size: 16px;}

</style>