
//import { loadScript } from "vue-plugin-load-script";
/* eslint-disable */
export default {
  data() {
    return {
      currentPageName: "",
      commonIsCookieExist : false,
      cookiesInfo : { //cookie 정보.
        kt_encid        : null,
        kt_credtid      : null,
        kt_userid       : null,
        kt_activationid : null,
        kt_mactiyn      : null,
        kt_auserid      : null,
        kt_acredtid     : null,
        kt_aidtype      : null,
        kt_token        : null,
        kt_token_id     : null
      },
      decryptedInfo : { //복호화 정보.
        userid     : null,
        credtid    : null,
        mactiyn    : null,
        auserid    : null,
        acredtid   : null,
        aidtype    : null,
        tokenid    : null,
        credttypecd: null
      },
      IsKTuser : 0,
      IsLogin  : 0,
      isCookieSet: false,
      currentGuideComponent : "CompSpeedTestGuide"
    };
  },
  beforeCreate() {},
  async created () {
    this.setAppEventBus("$on");
  },
  mounted() {},
  beforeUpdate(){},
  updated() {},
  beforeDestroy(){},
  destroyed() {
    this.setAppEventBus("$off");
  },
  methods: {
    //** --------------------------------------------------------
    setAppEventBus(type) {
      switch( type ) {
        case "$on" :
          this.$eventBus.$on("setCurrentPageName", this.setCurrentPageName);
          this.$eventBus.$on("setCurrentGuideComponent", this.setCurrentGuideComponent);
          this.$eventBus.$on("goInstallGuidePage", this.goInstallGuidePage);
          break;
        case "$off":
          this.$eventBus.$off("setCurrentPageName", this.setCurrentPageName);
          this.$eventBus.$off("setCurrentGuideComponent", this.setCurrentGuideComponent);
          this.$eventBus.$off("goInstallGuidePage", this.goInstallGuidePage);
          break;
        default : break;
      }
    },
    //** --------------------------------------------------------
     returnUrl(url) {
      return this.$store.getters.url.WASURL + url;
    },
    //** --------------------------------------------------------
    async returnLoginYn() {
      return !!window.kt && window.kt.isLogin()? window.kt.isLogin() : "N";
    },
    //** --------------------------------------------------------
    setCurrentPageName(obj) {
      this.currentPageName = !!obj.currentPageName ? obj.currentPageName : "";

      //속도측정길잡이 > 측정 프로그램 설치 탭 선택.
      if ( !!obj && !!obj.routeParams && !!obj.routeParams.currentGuideComponent ) {
        this.setCurrentGuideComponent({ currentGuideComponent : obj.routeParams.currentGuideComponent });
      }
    },
    /**
     * 속도측정길잡이 > 탭 선택.
     */
    setCurrentGuideComponent(obj) {
      this.currentGuideComponent = !!obj.currentGuideComponent? obj.currentGuideComponent : "CompSpeedTestGuide";
    },
    /**
     * 에이전트 설치 화면으로 이동.
     */
    goInstallGuidePage(obj) {
      if ( !!this.$router ) {
        this.$router.push({name:"Guide", params: { currentGuideComponent : "CompInstallGuide" }}, (a)=>{
        });
      }
    },
    setHeader(obj) {
      this.$http.defaults.headers.common['Authorization'] = !!obj? obj : undefined;
    },
    /** --------------------------------------------------------
     * cookie value 초기화.
     * 복호화 관련 value 초기화.
     */
     async initCommonMixinsData() {
      this.setHeader(null);
      await this.comonIsCookieExistCheck("init", this.cookiesInfo); //this.commonIsCookieExist = false;

      for(var cookiesInfokey in this.cookiesInfo ) {
        this.cookiesInfo[cookiesInfokey]  = null;
      }

      for(var decryptedInfokey in this.decryptedInfo) {
        this.decryptedInfo[decryptedInfokey] = null;
      }
      
      this.isCookieSet = false;
    },
    /** --------------------------------------------------------
     * 원본 cookie값에 해당하는 objName return.
     * @param {*} type 
     * @param {*} originalCookieName 
     * @returns 
     */
    async returnCookiesMappingName(type, originalCookieName) {
      var cookieMappingNameAllInfo = {
        defaultValueSet: {
          kt_sso_encid        : "kt_encid",
          kt_sso_credtid      : "kt_credtid",
          kt_sso_userid       : "kt_userid",
          kt_sso_activationid : "kt_activationid",
          kt_sso_token        : "kt_token"
        },
        parsingActivationid: {
          kt_sso_mactiyn  : "kt_mactiyn",
          kt_sso_auserid  : "kt_auserid",
          kt_sso_acredtid : "kt_acredtid",
          kt_sso_aidtype  : "kt_aidtype"
        },
        parsingToken: {
          kt_sso_token : "kt_token_id"
        }
      };

      var mappingNameInfo = !!cookieMappingNameAllInfo[type]? cookieMappingNameAllInfo[type] : null;
      //type에 해당하는 mapping정보가 있으면 cookie key값에 해당하는 name return.
      var tempCookieName = null;
      if ( !!mappingNameInfo && !!mappingNameInfo[originalCookieName]) {
        tempCookieName = mappingNameInfo[originalCookieName];
      }
      return tempCookieName;
    },
    //** --------------------------------------------------------
    async pageInitDefault () {
      await this.initCommonMixinsData();
      await this.setDefaultCookieValue();
      await this.pageEventEmit("pageInitDefault");
    },
    /** --------------------------------------------------------
     *
     */
    async pageEventEmit(callEventName) {
      var list = []; //상품목록 조회.
      if ( !!this.currentPageName ) {
        //list = await this.getOwnerAllStatusSubscpnTypeInfoRequest(this.currentPageName);
        list = await this.getOwnerSubscpnWithOfficeByPaging();
      }
      switch (this.currentPageName) {
        case "SpeedTestIntroduce":
          this.$eventBus.$emit("SpeedTestIntroduce/setProductList", list);
          break;
        case "SpeedHistory":
          this.$eventBus.$emit("SpeedHistory/getSpeedTestHistory", { decryptedInfo : this.decryptedInfo });
          break;
        // case "SLATestIntroduce":
        //   if ( callEventName == "goTest" ) {
        //     this.$eventBus.$emit("SLATestIntroduce/setProductList", list);
        //   }
        //   break;
        case "SLAHistory" :
          this.$eventBus.$emit("SLAHistory/getSLATestHistory", { decryptedInfo : this.decryptedInfo, list : list});
          break;
        case "SLAComplain" :
          this.$eventBus.$emit("SLAComplain/getSLAComplainHistory", { decryptedInfo : this.decryptedInfo, list : list});
          break;
        default: break;
      }
    },
    /** --------------------------------------------------------
     * 
     * @param {*} type 
     * @param {*} obj 
     * @returns 
     */
    async comonIsCookieExistCheck(type, obj) {
      // type = init, set, return
      var tempIsExistBoolean = false;
      switch(type) {
        case "set"    :
        case "return" :
            tempIsExistBoolean = !!obj.kt_token? true : false;
            break;
        default : break;
      }

      if ( type !="return" ) {
        this.commonIsCookieExist = tempIsExistBoolean;
      }
      return tempIsExistBoolean;
    },
    /** --------------------------------------------------------
     * 매핑 된 cookie name에 해당하는 값을 파싱해서 this.cookiesInfo set.
     * @param {*} baseCookiesInfo => this.cookiesInfo
     * @param {*} mappedCookieName => kt_activationid, kt_token
     * @param {*} mappingNameType => "parsingActivationid", "parsingToken"
     */
    async setCookiesInfo(baseCookiesInfo, mappedCookieName, mappingNameType) {
      var returnBoolean = false;
      if ( !!baseCookiesInfo[mappedCookieName] ) {
        baseCookiesInfo[mappedCookieName] = decodeURI(baseCookiesInfo[mappedCookieName]);
        
        var list = baseCookiesInfo[mappedCookieName].split("|");
        for(var index in list) {
          var arrItem = list[index].split("=");

          if ( !!arrItem && arrItem.length > 0 ) {
            var itemName = await this.returnCookiesMappingName(mappingNameType, arrItem[0]);
            if ( !!itemName ) {
              //this.cookiesInfo[itemName] = await this.$ktSpeedPlugin.makeKTCookieMultivalueString(arrItem, "=");
              baseCookiesInfo[itemName] = await this.$ktSpeedPlugin.makeKTCookieMultivalueString(arrItem, "=");
              
              if ( mappingNameType=="parsingToken" ) { 
                returnBoolean = true;
              }
            }
          }
        }
      }
      return returnBoolean;
    },
    /** --------------------------------------------------------
     * $cookies값이 있으면 default value set.
     * 
     * $cookies에 kt_activationid=kt_sso_activationid 이 있으면 URLDecode 후 "|"기준으로 split.
     */
    async setDefaultCookieValue() {
      //st. default cookie set.
      if ( this.$cookies.keys().length > 0 ) {
        for(var originalCookieName of this.$cookies.keys()) {
          var tempValueName = await this.returnCookiesMappingName("defaultValueSet", originalCookieName);
          if ( !!tempValueName ) {
            //this.cookiesInfo[tempValueName] = this.$cookies.get(originalCookieName);
            this.$set(this.cookiesInfo, tempValueName, this.$cookies.get(originalCookieName));
          }
        }
      }

      //default cookie값 set => session 정보 set.
      await this.setDefaultSessionValue();

      //cookie 유무
      await this.comonIsCookieExistCheck("set", this.cookiesInfo);

      // kt_activationid 파싱 "kt_activationid";
      await this.setCookiesInfo(this.cookiesInfo, "kt_activationid", "parsingActivationid");

      // kt_token 파싱
      var isCookieSet = await this.setCookiesInfo(this.cookiesInfo, "kt_token", "parsingToken");
      this.isCookieSet = !!isCookieSet ? isCookieSet : false;

      this.setHeader(this.cookiesInfo.kt_token_id);
    },
    /** --------------------------------------------------------
     * session default set.
     * cookie 값에 토큰이 없으면 session 초기화.
     */
    async setDefaultSessionValue() {
      // 토큰값이 없으면 session 초기화.
      if ( !this.cookiesInfo.kt_token ) {
        this.$session.clear();
        this.$session.destroy();
      }

      //session이 있으면 복호화 관련 값에 set.
      if ( this.$session.exists() ) {
        for(var keyName in this.$session.getAll()) {
          switch(keyName) {
            case "userid"      :
            case "credtid"     :
            case "mactiyn"     :
            case "auserid"     :
            case "tokenid"     :
            case "credttypecd" :
              this.decryptedInfo[keyName] = this.$session.get(keyName);
              break;
            case "aidtype"  :
            case "acredtid" :
              break;
            default : break;
          }
        }
      }
    },
    /** --------------------------------------------------------
     * 
     * @returns 
     */
    async returnCookieExistBoolean() {
      // cookie에 kt_sso_token이 있으면 true
      //var tempBoolean = !!this.$cookies.get("kt_sso_token")? true : false;
      this.commonIsCookieExist = tempBoolean;
      return tempBoolean;
    },
    /** --------------------------------------------------------
     * cookie에 있는 토큰값으로 복호화 정보 조회.
     * @param {*} kt_token_id 
     * @returns 
     */
    async getAuthInfoByTokenId(kt_token_id) {
      var params = {
        kt_token_id : kt_token_id
      };
      return await this.$http.post(this.returnUrl("/login/getAuthInfoByTokenId"), params).then(
        async (response)=>{
          var resultData = !!response.data? response.data : null;
          return resultData;
      }).catch((error)=> {
          return null;
      });
    },
    /** --------------------------------------------------------
     * cookie에 있는 토큰값으로 복호화 정보 조회 후 session값 set.
     * @returns
     */
    async checkKtTokenId() {
      var credtid = null;
      if (!!this.cookiesInfo.kt_token_id) {

        // 결과값이 있으면 IsLogin = 1
        var result = await this.getAuthInfoByTokenId(this.cookiesInfo.kt_token_id);
        this.IsLogin = !!result ? 1 : 0;
        
        //세션의 토큰값과 다르면 session값 set.
        if (!!result && (this.cookiesInfo.kt_token_id != this.decryptedInfo.tokenid)) {
          //this.$session.set(keyName);
          credtid = result.credt_id;
          
          //복호화 정보.
          this.decryptedInfo.credtid      = result.credt_id;
          this.decryptedInfo.auserid      = result.active_id;
          this.decryptedInfo.mactiyn      = result.multi_active_id_yn;
          this.decryptedInfo.userid       = result.user_name;
          this.decryptedInfo.credttypecd  = result.credt_type_cd;
          
          // this.$session.set("credtid"    , result.credt_id);
          // this.$session.set("auserid"    , result.active_id);
          // this.$session.set("mactiyn"    , result.multi_active_id_yn);
          // this.$session.set("userid"     , result.user_name);
          // this.$session.set("credttypecd", result.credt_type_cd);
        }
      }
      return credtid;
    },
    /** --------------------------------------------------------
     * 상품목록 조회.
     * - 속도테스트
     * - SLA테스트
     */
    async getOwnerAllStatusSubscpnTypeInfoRequest() {
      var list = [];
      var credtid = await this.checkKtTokenId();
      if ( !!credtid ) {
        var params = {
          credtid : credtid,
          kt_token_id : this.cookiesInfo.kt_token_id
        };
        var result = await this.$http.post(this.returnUrl("/login/getOwnerAllStatusSubscpnTypeInfoRequest"), params).then(
          async (response)=>{
            return !!response.data? response.data : null;
        }).catch((error)=> {
            return null;
        });
        
        if ( !!result && !!result.list ) {
          var tempList = result.list;
          for (var item of tempList) {
            var tempItem = {
              said           : item.msaid,
              product_id     : item.mprodcd,
              product_name   : item.mprodname,
              installaddress : item.maddress,
              branchname     : item.branchname,
              agentname      : item.agentname,
              officename     : item.officename
            };
            
            //조회 된 상품목록정보에 있는 주소 마스킹 처리.
            if ( !!item.maddress ) {
              tempItem.installaddress = await this.$ktSpeedPlugin.FormatMaskingAddressText(item.maddress, 10);
            }
            
            list.push(tempItem);
          }
        }
      }
      return list;
    },
    /**  --------------------------------------------------------
     * 30건 이상의 상품목록을 페이지 별로 조회.
     * getOwnerAllStatusSubscpnTypeInfoRequest 대체 할 SHUB API ( OIF_251 )
     * @returns 
     */
     async getOwnerSubscpnWithOfficeByPaging() {
      // var credtid = await this.checkKtTokenId();
      var list = [];
      // if ( !!credtid ) {
      if(!!this.cookiesInfo.kt_token_id){
        // var params = {
        //   kt_token_id   : this.cookiesInfo.kt_token_id,
        // };
        var result = await this.$http.post(this.returnUrl("/login/getOwnerSubscpnWithOfficeByPaging")).then(
          async (response)=>{
            return !!response.data? response.data : null;
        }).catch((error)=> {
            return null;
        });

        if ( !!result && !!result.list ) {
          var tempList = result.list;
          for (var item of tempList) {
            var tempItem = {
              said           : !!item.icis_sa_id? item.icis_sa_id : "",
              product_id     : !!item.pp_cd? item.pp_cd : "",
              product_name   : !!item.pp_name? item.pp_name : "",
              installaddress : !!item.install_address? item.install_address : "",
              branchname     : !!item.branch_name? item.branch_name : "-",
              agentname      : !!item.agent_name? item.agent_name: "-",
              officename     : !!item.office_name? item.office_name: "-",
              subscpn_id     : !!item.subscpn_id? item.subscpn_id: "-",
              pp_oder_flag   : !!item.pp_oder_flag? item.pp_oder_flag:""
            };

            list.push(tempItem);
          }
        }
      }
      return list;
    },
    /** --------------------------------------------------------
     * 최초 said 조회.
     * @param {*} decryptedInfo 
     * @param {*} selectedProductInfo 
     */
    // async getOTMOwnerSubscpnInfoByUserName(decryptedInfo, selectedProductInfo) {
      async getOTMOwnerSubscpnInfoByUserName( selectedProductInfo) {
      var mSAID = "";
      if ( !!selectedProductInfo && !!selectedProductInfo.said ) {
        var params = {
           lastsaid    : selectedProductInfo.said
        };

        var result = await this.$http.post(this.returnUrl("/login/getOTMOwnerSubscpnInfoByUserName"), params).then(
          async (response)=>{
            return !!response.data? response.data : null;
        }).catch((error)=> {
            return null;
        });
        
        if ( !!result ) {
          mSAID = result.mSAID;
        }
      }
      return mSAID;
    },
    /** --------------------------------------------------------
     * returnCode = 0 이면 totUseqntEvAplyThrs = "N"
     * returnCode = 1 이고 totUseqntEvAplyThrs값이 있으면 totUseqntEvAplyThrs
     * - totUseqntEvAplyThrs = "Y" :: 데이터 일일사용총량 초과
     * @param {*} decryptedInfo 
     * @param {*} selectedProductInfo 
     * @returns
     */
    async getAuthInfoByQos(decryptedInfo, selectedProductInfo) {
      var params = {
          // userid      : decryptedInfo.userid
        // , credttypecd : decryptedInfo.credttypecd
         lastsaid    : selectedProductInfo.said
      };
      var result = await this.$http.post(this.returnUrl("/login/getAuthInfoByQos"), params).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch((error)=> {
          return null;
      });
      return !!result && !!result.totUseqntEvAplyThrs? result.totUseqntEvAplyThrs : "N";
    },
    /** --------------------------------------------------------
     * 상품정보 조회.
     * @param {*} product_id 
     * @returns 
     */
    async getProductInfo(product_id) {
      var params = {
        product_code : product_id,
      };
      return await this.$http.post(this.returnUrl("/speedtest/getProductInfo"), params ).then(
        async (response)=>{
          var resultData = !!response.data? response.data : null;
          return resultData;
      }).catch((error)=> {
          return null;
      });
    },
    /** --------------------------------------------------------
     * 조회 된 상품정보의 측정타입에 해당하는 측정서버 정보 조회.
     * @param {*} productInfo 
     * @returns 
     */
    async getTargetServerInfo(productInfo) {
      var params = {
        mtype : productInfo.mtype,
      };
      return await this.$http.post(this.returnUrl("/speedtest/getTargetServerInfo"), params ).then(
        async (response)=>{
          var resultData = !!response.data? response.data : null;
          return resultData;
      }).catch((error)=> {
          return null;
      });
    },
    /** --------------------------------------------------------
     * PROCESSOR return.
     * @param {*} productInfo 
     * @returns 
     */
    returnProcessor(testType, productInfo) {
      var measureType   = !!productInfo? productInfo.mtype : "0";
      var processorName = "";
      switch(String(measureType)) {
        case "0" : processorName = testType=="speedTest"? "TEST-SPEED-100M" : "TEST-SLA-100M";
          break;
        case "1" : processorName = testType=="speedTest"? "TEST-SPEED-1G" : "TEST-SLA-1G";
          break;
        case "2" : processorName = testType=="speedTest"? "TEST-SPEED-10G" : "TEST-SLA-10G";
          break;
        default  : processorName = testType=="speedTest"? "TEST-SPEED-1G" : "TEST-SLA-100M";
          break;
      }
      return processorName;
    },
    /**
     * 측정서버 ip return.
     * @param {*} obj 
     */
    returnMeasureIp(testType, productInfo, targetServerInfo) {
      var speedTestIp = !!targetServerInfo? targetServerInfo.vipaddress : "";
      
      if ( !speedTestIp ) {
        var measureType   = !!productInfo? productInfo.mtype : "0";
        switch(measureType) {
          case "0" : speedTestIp = this.$store.getters.measurementOption.DefaultCommMServer;
            break;
          case "1" : speedTestIp = this.$store.getters.measurementOption.DefaultGigaMServer;
            break;
          case "2" : speedTestIp = this.$store.getters.measurementOption.Default10GigaMServer;
            break;
          default  : speedTestIp = this.$store.getters.measurementOption.DefaultCommMServer;
            break;
        }
      }
      return speedTestIp;
    },
    /**
     * 현재 접속한 브라우저 정보.
     * @returns 
     */
    returnBrowserInfo() {
      var browser = this.$browserDetect.meta.ua
      if(browser.includes('Whale')){
        browser = 'Whale'
      }else if(browser.includes('FxQuantum')){
        browser = 'Firefox'
      }else{
        for(var objName in this.$browserDetect) {
          if ( !!this.$browserDetect[objName] ) {
            switch(objName) {
              case "isBrave" :
                browser = 'Brave'
                break;
              case "isChrome" :
              case "isChromeIOS" :
                browser = 'Chrome'
                break;
              case "isEdge" :
                browser = 'Edge'
                break;
              case "isFirefox" :
                browser = 'Firefox'
                break;
              case "isIE" :
                browser = 'IE'
                break;
              case "isOpera" :
                browser = 'Opera'
                break;
              case "isSafari" :
                browser = 'Safari'
                break;
              default : 
                browser = this.$browserDetect.meta.name
                break;
            }
            break;
          }
        }
      }
      return browser;
    },
    returnMeasureTestStartParameter(testType, processor, measureIp, browserName) {
      var startReturn = {};
      if ( testType == "speedTest") {
        startReturn = {
          TYPE:'REQUEST',
          PROCESSOR: processor,
          SERVER_IP_SPEED: measureIp,
          BROWSER_CLIENT: browserName,
          CALLBACK_ERROR: "ERROR_FN",
          CALLBACK_EXCEPTION: "EXCEPTION_FN",
          CALLBACK_RESULT: "RESPONSE_FN",
        };
      } else {
        startReturn = {
          "TYPE" :"RESPONSE",
          "PROCESSOR" : processor, //일반 "TEST-SLA-100M" , 기가  "TEST-SLA-1G" 10G "TEST-SLA-10G"
          "SERVER_IP_SPEED" :measureIp,
          "BROWSER_CLIENT" :browserName,
          "CALLBACK_RESULT" :"RESPONSE_FN",
          "CALLBACK_ERROR" :"ERROR_FN",
          "CALLBACK_EXCEPTION" :"EXCEPTION_FN",
          "SLA_STD" :25
        };
      }
      return startReturn;
    },
    /**
     * IsOk 체크 필요.
     * @returns 
     */
    async pageAuthCheck () {
      // '토큰값이 없으면 세션초기화 kt_sso_token
      // If trim(kt_token) = "" Then
      //   Session.Contents.RemoveAll()
      //   Session.Abandon
      // End if

      this.$cookies.get("kt_sso_token");
      var tempCookieInfo = {
        kt_sso_encid: "",
        kt_sso_credtid: "",
        kt_sso_userid: "",
        kt_sso_activationid: "",
        kt_sso_token: "",
      };
      for (var cookieskey in tempCookieInfo) {
        tempCookieInfo[cookieskey] = this.$cookies.get(cookieskey);
      }

      var tempSessionInfo = {
        userid: "",
        credtid: "",
        mactiyn: "",
        auserid: "",
        tokenid: "",
        credttypecd: "",
      };
    
      for (var sessionkey in tempSessionInfo) {
        tempSessionInfo[sessionkey] = this.$cookies.get(sessionkey);
      }
      return false;
    },
    /**
     * 
     */
    async insertProductInfo(params) {
      var insertProductParams = {
          product_name      : params.product_name,
        //  upload_sla        : this.$store.getters.measurementOption.DefaultSLAThresHold, //was에서 default 값 set.
        //  download_sla      : this.$store.getters.measurementOption.DefaultSLAThresHold, //was에서 default 값 set.
          product_code      : params.product_id,
        //  download_sla_xdsl : this.$store.getters.measurementOption.DefaultSLAThresHold //was에서 default 값 set.
        // maxdownloadband   : 
        // maxuploadband     : 
        // mtype             :
        // isnew             :
      };
      return await this.$http.post(this.returnUrl("/speedtest/insertProductInfo"), insertProductParams).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch( async (error)=> {
          return null;
      });
    },
    /**
     * strSQLPrcInsSlaUserData // sla측정 사용자 정보 insert. => return sla_index(mPKID)
     */
    async insertSlaUserData(selectedProductInfo) {
      var mSAID = await this.getOTMOwnerSubscpnInfoByUserName(selectedProductInfo);
      var mTotUseqntEvAplyThrs = await this.getAuthInfoByQos(this.decryptedInfo, selectedProductInfo);

      var productInfo = await this.getProductInfo(selectedProductInfo.product_id);

      //상품코드가 상품테이블에 없으면 신규등록
      if ( !productInfo ) {
        productInfo = await this.insertProductInfo(selectedProductInfo);
      }
      
      var params = {
          // v_user_id             : this.decryptedInfo.userid,
          // v_user_id             : this.decryptedInfo.userid == null || this.decryptedInfo.userid == '' ? this.$session.get('userid') : this.decryptedInfo.userid,
          v_sidoindex           : null, //0
          v_gugunindex          : null, //0
          v_product_id          : selectedProductInfo.product_id,
          v_product_name        : selectedProductInfo.product_name,
          v_sa_id               : mSAID,
          v_branch_name         : selectedProductInfo.branchname,
          v_agent_name          : selectedProductInfo.agentname,
          v_office_name         : selectedProductInfo.officename,
          //v_qindex              : 0,
          v_totuseqntevaplythrs : mTotUseqntEvAplyThrs,
          v_last_sa_id          : selectedProductInfo.said,
          v_order_flag          : selectedProductInfo.pp_oder_flag
          
      };
      //spinsslauserdata => sla_index return 받아야됨.
      var result = await this.$http.post(this.returnUrl("/speedtest/insertSlaUserData"), params).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch((error)=> {
          return null;
      });
      
      // ANTM연동 수정 230508
      // var temp_sla_index = !!result && !!result.sla_index? result.sla_index : null;
      // return {
      //   mSAID               : mSAID,
      //   sla_index           : temp_sla_index,
      //   totuseqntevaplythrs : mTotUseqntEvAplyThrs
      // };
      var temp_sla_index = !!result && !!result.sla_index? result.sla_index : null;
      var ip = !!result && !!result.ip? result.ip : null;
      var mac = !!result && !!result.mac? result.mac : null;
      return {
        mSAID               : mSAID,
        sla_index           : temp_sla_index,
        totuseqntevaplythrs : mTotUseqntEvAplyThrs,
        ip : ip,
        mac : mac
      };
      // ANTM연동 수정 230508
    },
    /**
     * ANTM연동.
     */
    // ANTM연동 수정 230605
    // async certificationUser(decryptedInfo, insertSlaResultData, insertSLATestResultParams) {
    certificationUser(decryptedInfo, insertSlaResultData, insertSLATestResultParams) {
      var params = {
          lastsaid    : insertSlaResultData.mSAID
        , ip          : insertSLATestResultParams.v_external_address
        , mac         : insertSLATestResultParams.v_mac
        , slaIndex    : insertSLATestResultParams.sla_index
        , scn         : insertSLATestResultParams.v_scn
        , userid      : this.decryptedInfo.userid
      };
      // return await this.$http.post(this.returnUrl("/login/certificationUser"), params).then(
      // ANTM연동 수정 230605
      return this.$http.post(this.returnUrl("/login/certificationUser"), params).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch((error)=> {
          return null;
      });
    },
    /**
     * ANTM연동 결과값으로 SLA 정보 업데이트.
     */
    async updateSlaSpeedData(params) {
      return await this.$http.post(this.returnUrl("/speedtest/updateSlaSpeedData"), params).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch((error)=> {
          return null;
      });
    },
    /**
     * SLA 테스트 5회 까지 진행 후
     * SLA 미달여부 업데이트.
     */
    async updateslaStandardYn(insertSlaResultData) {
      var updateParams = {
        sla_index  : insertSlaResultData.sla_index
      };
      return await this.$http.post(this.returnUrl("/speedtest/updateslaStandardYn"), updateParams).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch((error)=> {
          return null;
      });
    },
    /**
     * CEMS 연동 호출 진행
     * SLA 측정 4회차까지 2회 미달인 경우 진행
     */
    async speedKTCheck(cemsParams){
      var params = {
        v_sla_index  : cemsParams.sla_index,
        v_scn        : cemsParams.mSAID,
        v_sa_id      : cemsParams.mSAID,
        v_product_id : cemsParams.product_id
       
      }; 
      return await this.$http.post(this.returnUrl("/speedtest/speedKTCheck"), params).then(
        async (response)=>{
          return !!response.data? response.data : null;
      }).catch((error)=> {
          return null;
      });
    },
    /**
     * CEMS 결과 호출 
     * 
     */
    async speedKTCheckResult(cemsResultParams){
      var params = {
        v_sla_index : cemsResultParams.sla_index,
        v_jobId : cemsResultParams.jobId
      };

      return await this.$http.post(this.returnUrl("/speedtest/speedKTCheckResult"), params).then(
        async (response)=>{  
          return !!response.data? response.data : null;
      }).catch((error)=> {
          return null;
      });
      
    }
  },
  
};